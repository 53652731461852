// :cow: Cosmose CONFIDENTIAL :iso:
import { Action, createReducer, on } from '@ngrx/store';
import {
  getImageSuggestionVariantDetailsFailed,
  getImageSuggestionVariantDetailsNextPageFailed,
  getImageSuggestionVariantDetailsNextPageRequested,
  getImageSuggestionVariantDetailsNextPageSucceeded,
  getImageSuggestionVariantDetailsRequested,
  getImageSuggestionVariantDetailsSucceeded,
  setImageSuggestionVariantDetailsLoading,
} from './image-suggestion-variant-details.actions';
import { ImageSuggestionVariantDetailsResponse } from '../models/image-suggestion-variant-details.response';

export const STATE_NAME_IMAGE_SUGGESTION_VARIANT_DETAILS = 'image-suggestion-variant-details';

export interface State {
  detailsIsLoading: boolean;
  detailsIsError: boolean;
  detailsResponse: ImageSuggestionVariantDetailsResponse;
  isUpdating: boolean;
}

const initialState: State = {
  detailsIsLoading: false,
  detailsIsError: false,
  detailsResponse: undefined,
  isUpdating: false,
};

const reducer = createReducer(
  initialState,
  on(getImageSuggestionVariantDetailsRequested, (state: State) => ({
    ...state,
    detailsResponse: undefined,
    detailsIsLoading: true,
    detailsIsError: false,
  })),
  on(getImageSuggestionVariantDetailsSucceeded, (state: State, {response}) => ({
    ...state,
    detailsResponse: {
      ...response,
      images: [
        ...state.detailsResponse?.images || [],
        ...response?.images.slice().sort((a, b) => response.page_info.page_number % 2 ? b.thumbnail_height - a.thumbnail_height : a.thumbnail_height - b.thumbnail_height),
      ],
    },
    detailsIsLoading: false,
  })),
  on(getImageSuggestionVariantDetailsFailed, (state: State) => ({...state, detailsIsLoading: false, detailsIsError: true})),

  on(getImageSuggestionVariantDetailsNextPageRequested, (state: State) => ({
    ...state,
    detailsIsLoading: true,
    detailsIsError: false,
  })),
  on(getImageSuggestionVariantDetailsNextPageSucceeded, (state: State, {response}) => ({
    ...state,
    detailsResponse: {
      ...state.detailsResponse,
      images: [
        ...state.detailsResponse?.images,
        ...response.images.slice().sort((a, b) => response.page_info.page_number % 2 ? b.thumbnail_height - a.thumbnail_height : a.thumbnail_height - b.thumbnail_height),
      ],
    },
    detailsIsLoading: false,
  })),
  on(getImageSuggestionVariantDetailsNextPageFailed, (state: State) => ({...state, detailsIsLoading: false, detailsIsError: true})),
  on(setImageSuggestionVariantDetailsLoading, (state: State, {loading}) => ({...state, detailsIsLoading: loading})),
);

export function imageSuggestionVariantDetailsReducer(state: State | undefined, action: Action): any {
  return reducer(state, action);
}

