// :cow: Cosmose CONFIDENTIAL :iso:
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from '@par/app/core/services/config.service';
import { LocalStorageKey } from '@par/app/core/model/enums/local-storage-key';
import { ImageSuggestionVariantDetailsPayload } from '../models/image-suggestion-variant-details.payload';
import { ImageSuggestionVariantDetailsResponse } from '../models/image-suggestion-variant-details.response';
import { SuggestedImageUrlResponse } from '../models/suggested-image-url.response';
import { SuggestedImageUrlPayload } from '../models/suggested-image-url.payload';
import { ImageSuggestionListVariant } from '@par/app/shared/image-suggestion-variant-list-dialog/models/image-suggestion-list.variants';

@Injectable({
  providedIn: 'root',
})
export class ImageSuggestionVariantDetailsService {

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService,
  ) {
  }

  getImageSuggestionVariantDetails(payload: ImageSuggestionVariantDetailsPayload): Observable<ImageSuggestionVariantDetailsResponse> {
    const url = `${ this.configService.getConfig().mindBrushApi }/v2/content_images`;
    const variant = payload.variant === ImageSuggestionListVariant.LS ? 'LOCKSCREEN' : 'WEB_CONTENT';

    const params = new HttpParams()
      .set('kkn_content_id', payload.resourceId)
      .set('updated_at', payload.updatedAt)
      .set('source', payload.source)
      .set('page_number', payload.page)
      .set('image_type', variant)
      .set('image_orientation', payload.orientation)
      .set('page_size', payload.size);

    return this.httpClient.get<ImageSuggestionVariantDetailsResponse>(url, {params});
  }

  getSuggestedImageUrl(payload: SuggestedImageUrlPayload): Observable<SuggestedImageUrlResponse> {
    const adobeToken = JSON.parse(localStorage.getItem(LocalStorageKey.adobeTokenResponse))?.access_token || '';
    const variant = payload.variant === ImageSuggestionListVariant.LS ? 'LOCKSCREEN' : 'WEB_CONTENT';

    const params = new HttpParams()
      .set('kkn_content_id', payload.id)
      .set('adobe_id', payload.image.image_id)
      .set('image_type', variant)
      .set('source', payload.source);

    const url = `${ this.configService.getConfig().mindBrushApi }/get_full_image`;
    return this.httpClient.get<any>(url, {params, headers: {'X-Adobe-Authorization-Token': adobeToken}});
  }
}
