// :cow: Cosmose CONFIDENTIAL :iso:
import { Action, createReducer, on } from '@ngrx/store';
import {
  getImageSuggestionVariantListFailed,
  getImageSuggestionVariantListRequested,
  getImageSuggestionVariantListSucceeded,
} from './image-suggestion-variant-list.actions';
import { ImageSuggestionVariantListResponse } from '@par/app/shared/image-suggestion-variant-list-dialog/models/image-suggestion-variant-list.response';

export const STATE_NAME_IMAGE_SUGGESTION_VARIANT_LIST = 'image-suggestion-variant-list';

export interface State {
  listIsLoading: boolean;
  listIsError: boolean;
  listResponse: ImageSuggestionVariantListResponse;
}

const initialState: State = {
  listIsLoading: false,
  listIsError: false,
  listResponse: undefined,
};

const reducer = createReducer(
  initialState,
  on(getImageSuggestionVariantListRequested, (state: State) => ({
    ...state,
    listResponse: undefined,
    listIsLoading: true,
    listIsError: false,
  })),
  on(getImageSuggestionVariantListSucceeded, (state: State, {response}) => ({...state, listResponse: response, listIsLoading: false})),
  on(getImageSuggestionVariantListFailed, (state: State) => ({...state, listIsLoading: false, listIsError: true})),
);

export function imageSuggestionVariantListReducer(state: State | undefined, action: Action): any {
  return reducer(state, action);
}

