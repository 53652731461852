// :cow: Cosmose CONFIDENTIAL :iso:
import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ImageSuggestionVariantDetailsPayload } from '../models/image-suggestion-variant-details.payload';
import { ImageSuggestionVariantDetailsResponse } from '../models/image-suggestion-variant-details.response';

export const getImageSuggestionVariantDetailsRequested = createAction('[IMAGE SUGGESTIONS] Get Image Suggestion Variant Details Requested', props<{
  payload: ImageSuggestionVariantDetailsPayload
}>());
export const getImageSuggestionVariantDetailsSucceeded = createAction('[IMAGE SUGGESTIONS] Get Image Suggestion Variant Details Succeeded', props<{
  response: ImageSuggestionVariantDetailsResponse
}>());
export const getImageSuggestionVariantDetailsFailed = createAction('[IMAGE SUGGESTIONS] Get Image Suggestion Variant Details Failed', props<{
  httpErrorResponse: HttpErrorResponse
}>());


export const getImageSuggestionVariantDetailsNextPageRequested = createAction('[IMAGE SUGGESTIONS] Get Image Suggestion Variant Details Next Page Requested', props<{
  payload: ImageSuggestionVariantDetailsPayload
}>());
export const getImageSuggestionVariantDetailsNextPageSucceeded = createAction('[IMAGE SUGGESTIONS] Get Image Suggestion Variant Details Next Page Succeeded', props<{
  response: ImageSuggestionVariantDetailsResponse
}>());
export const getImageSuggestionVariantDetailsNextPageFailed = createAction('[IMAGE SUGGESTIONS] Get Image Suggestion Variant Details Next Page Failed', props<{
  httpErrorResponse: HttpErrorResponse
}>());

export const setImageSuggestionVariantDetailsLoading = createAction('[IMAGE SUGGESTIONS] Set Image Suggestion Variant Details Loading', props<{
  loading: boolean
}>());
